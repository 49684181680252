* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Exo 2', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #404040;
  background: #fff;
  // overflow: auto;
}

a img {
  border: 0px;
}
button:focus {outline: 0px;}
h2 {font-size: 1.5rem;}
.text--blue {
  color: #1d70b8;
}
.text--back {
  color: #19171c;
}

.mainmenu {
  height: auto;
  transition: all .3s;

  &.scrolled {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    z-index: 990;
    box-shadow: 0px 20px 25px -19px rgba(0,0,0,0.2);

    .mainmenu__top {
      height: 0px;
      padding: 0px 20px;
      overflow: hidden;
    }
    .mainmenu__bottom {
      background-color: #FFF;
      height: 60px;
      .logo-mls {
        height: 60px;
      }
    }
  }

  .mainmenu__top {
    background-color: #1d70b8;
    // height: 60px;
    padding: 10px 20px;
    
    p {
      color: #fff;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      margin: 0px auto;
    }
  }

  .mainmenu__bottom {
    .logo-mls {
      height: 100px;
      margin: 0px auto;

      @media (max-width: 480px) {
        height: auto;
        width: 100%;
      }
    }
  }
}

.header {
  position: relative;
  &:before {
    display: block;
    content: ' ';
    background-image: url('../img/sign.svg');
    background-size: 600px 600px;
    height: 600px;
    width: 600px;
    position: absolute;
    bottom: -150px;
    left: 0px;
    z-index: 2;
    
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &:after {
    display: block;
    content: ' ';
    background-image: url('../img/sign.svg');
    background-size: 600px 600px;
    height: 600px;
    width: 600px;
    position: absolute;
    bottom: -50px;
    right: 0px;
    z-index: 2;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .header__banner {
    background: url(../img/head_bg.png) no-repeat 0 -150px;
    background-size: cover;
    height: 800px;
    margin-bottom: 100px;
    z-index: 3;

    @media (max-width: 1920px) {
      height: 600px;
    }

    @media (max-width: 1440px) {
      background-position: 0 -50px;
      margin-bottom: 50px;
    }
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }

    .header__banner--infotext {
      color: #FFF;
      margin: 50px 0px;

      @media (max-width: 768px) {
        margin: 50px 0px 200px 0px;
      }

      .banner--count {
        font-size: 6rem;
        font-weight: bold;
        text-align: left; 
        display: flex;

        @media (max-width: 1600px) {
          font-size: 4rem;
        }

        @media (max-width: 768px) {
          font-size: 3rem;
          width: 380px;
          margin: 0px auto;
        }

        @media (max-width: 576px) {
          width: 310px;
          font-size: 2.8rem;
        }

        .animation--plus {
          animation: bounce .5s;
          animation-direction: alternate;
          //animation-iteration-count: infinite;
          animation-timing-function: ease;
          display: block;
          float: right;
          margin-right: 40px;

          @keyframes bounce {
            0% { 
              opacity: 0; 
              transform: scale(0,0);
              //transform: translate3d(0, 0, 0);     
            }
            50%  { 
              opacity: 1; 
              transform: scale(1.3,1.3);
              // transform: translate3d(0, 20px, 0); 
            }
            100% { 
              transform: scale(1,1);
              //transform: translate3d(0, 0, 0); 
            }
          }
        }
      }
      .banner--text {
        width: 380px;
        height: 130px;
        text-align: left;

        @media (max-width: 768px) {
          margin: 0px auto;
        }
        @media (max-width: 576px) {
          width: 310px;
        }
        
        p {
          font-size: 2.5rem;
          line-height: 2.7rem;
          border-right: .15em solid #FFF;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 0px;
          display: none;
          // float: right;
          // clear: both;

          @media (max-width: 768px) {
            font-size: 2rem;
            line-height: 2.2rem;
          }

          &:nth-child(1) {
            width: 200px;
            animation: type .5s steps(40, end);
            animation-fill-mode: forwards;
          }
          &:nth-child(2) {
            width: 380px;
            opacity: 0;
            font-weight: bold;
            animation: type2 .5s steps(40, end);
            animation-delay: .5s;
            animation-fill-mode: forwards;

            @media (max-width: 576px) {
              width: 310px;
            }
          }
          &:nth-child(3) {
            width: 330px;
            opacity: 0;
            animation: type3 .5s steps(40, end), blink .5s step-end infinite alternate;
            animation-delay: 1s;
            animation-fill-mode: forwards;
          }

          @keyframes type {
            0% {width: 0;}
            99.9% {border-right: .15em solid #fff;}
            100% {border: none;}
          }
          
          @keyframes type2 {
            0% {width: 0;}
            1% {opacity: 1;}
            99.9% {border-right: .15em solid #FFF;}
            100% {opacity: 1;border: none;}
          }
          
          @keyframes type3 {
            0% {width: 0;}
            1% {opacity: 1;}
            100% {opacity: 1; border-width: 0px;}
          }

          
        }

          // overflow: hidden; /* Ensures the content is not revealed until the animation */
          // border-right: .15em solid #FFF; /* The typwriter cursor */
          // white-space: nowrap; /* Keeps the content on a single line */
          // margin: 0 auto; /* Gives that scrolling effect as the typing happens */
          // letter-spacing: .15em; /* Adjust as needed */
          // animation:  typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
        
          // /* The typing effect */
          // @keyframes typing {
          //   from { width: 0 }
          //   to { width: 100% }
          // }
          
          // /* The typewriter cursor effect */
          // @keyframes blink-caret {
          //   from, to { border-color: transparent }
          //   50% { border-color: #FFF; }
          // }
      }
      .banner--button {
        margin-top: 50px;
        text-align: left;

        @media (max-width: 768px) {
          margin-top: 20px;
          text-align: center;
        }

        .btn {
          background-color: #FFF;
          border-radius: 20px;
          padding: 30px 50px;
          border: 1px #1d70b8 solid;
          transition: all 0.5s ease;
          // width: 380px;
          text-align: center;
          opacity: 0;

          &.active {
            opacity: 1;
          }

          @media (max-width: 768px) {
            width: 300px;
          }

          &:hover {
            animation: none;
            background-color: #1d70b8;
            span {
              padding-right: 25px;
              color: #FFF;

              &:after {
                opacity: 1;
                right: 0;
              }
            }
          }

          span {
            position: relative;
            font-size:  1.5rem;
            color: #1d70b8;
            text-transform: uppercase;
            transition: 0.5s;

            @media (max-width: 768px) {
              strong {display: block;}
            }

            &:after {
              content: '\00bb';
              font-size:  1.5rem;
              height: 25px;
              line-height: 25px;
              color: #FFF;
              position: absolute;
              opacity: 0;
              top: 0;
              right: -20px;
              transition: 0.5s;
            }
          }
        }
      }
    }

    svg {
      width: 60%;
      // height: 500px;
      position: absolute;
      bottom: -200px;
      left: 200px;

      @media (max-width: 1600px) {
        left: 100px;
        width: 80%;
        bottom: -150px;
      }

      @media (max-width: 1200px) {
        left: 0px;
        width: 100%;
        bottom: -50px;
      }

      // @media (max-width: 768px) {
      //   width: 80%;
      // }

      // @media (max-width: 576px) {
      //   width: 100%;
      // }
    }
  }
}

.atributemls {
  position: relative;
  z-index: 3;

  .atribute-box--content {
    &:last-child {
      margin-bottom: 40px;
    }

    @media (max-width: 768px) {
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .atribute__count--content {
      text-align: center;
      font-size: 2.2rem;
      line-height: 3rem;
      color: #1d70b8;

      > span:first-child {
        font-size: 4rem;
      }
    }
  }


  // .atributemls__section {
    .atributemls__title {
      margin-top: 100px;

      @media (max-width: 768px) {
        margin-top: 20px;
      }

      .text--blue {
        font-size: 2.5rem;
      }
      .text--black {
        font-size: 1.625rem;
        line-height: 1.75rem
      }
    }

    .atributemls__section--logo {
      // background-color: #faf8f8;
      height: 400px;
      padding-top: 100px;
      overflow: hidden;
      background: transparent url('../img/grey-bg.svg') no-repeat center top;
      background-size: cover;
      
      
      .logo {
        position: relative;
        width: 400px;
        margin: 0px auto;

        @media (max-width: 768px) {
          width: 80%;
        }
        @media (max-width: 576px) {
          width: 70%;
        }
      }

      svg {
        position: absolute;
        top: -100px; left: 0px;
        fill: #faf8f8;
        width: 100%;
      }
    }

    .atributemls__section--boxes {
      margin-top: -150px;
      background: transparent url('../img/content-bg.png') no-repeat center top;
      background-size: 100% 100%;
      padding: 100px 0px;

      @media (max-width: 1200px) {
        background-size: cover;
      }
      // background-image: linear-gradient(#1d70b8, #114d80);
      // height: 300px;

      // svg {
      //   position: absolute;
      //   top: -250px; left: 0px;
      //   fill: #1d70b8;
      //   width: 100%;
      // }

      .atribute-box {
        perspective: 1000px;
        transition: all 3.5s ease;
        // padding-top: 100px;

        .atribute-box--content {
          position: relative;
          transition: transform 0.8s;
          transform-style: preserve-3d;

          .atribute-box__square {
            box-shadow:  0px 0px 17px 0px rgba(0,0,0,.5);
            padding: 20px;
            margin: 15px;
            height: 200px;
            background-color: rgba(0, 0, 0, 0.3);
            // opacity: 0.6;
            // filter: alpha(opacity=60);
            border: 1px solid #FFF;
            border-radius: 30px;
            color: #FFF;
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 1.4rem;
            font-weight: 300;
            cursor: default;

            @media (max-width: 768px) {
              margin: 30px;
            }
          }

          &:hover {
            transform: scale(1.1);
            // transform: rotateY(180deg);

            // .atribute-box__square {
            //   transform: rotateY(180deg);
            // }
          }
        }
      }
    }
  }
// }


.benefitmls {
  .benefitmls__title {
    .benefitmls__title--text {
      font-size: 2rem;
      max-width: 920px;
      text-align: center;
      margin: 50px auto 0;
    }
  }
  .benefitmls__section--boxes {
      margin-top: 100px;
      height: 600px;
      // background: transparent url('../img/profit_bg.png') no-repeat center top;
      background: url('../img/profit_bg2.png') no-repeat center top;
      background-size: 100% 100%;
      transition: all 3.5s ease;
      // background-size: 100% 100%;
      // padding: 100px 0px;

      &:hover {
        background-size: 120% 120%;
      }

      .person-fade {
        position: absolute;
        height: 100%;
        z-index: 2;

        @media (max-width: 992px) {
          display: none;
        }
        img {
          height: 100%;
        }
      }

      .benefitmls-box {
        margin: 10% 0px 0px 40%;
        position: relative;
        z-index: 10;

        @media (max-width: 992px) {
          margin: 10% 0px 0px 0px;
        }
        h2 {
          color: #FFF;
          font-size: 2rem;
        }

        .benefit__element {
          .list-benefit {
            margin-top: 30px;
            .media {
              color: #FFF;
              margin: 20px 0px;
              img {
                height: 40px;
              }

              .media-body {
                h5 {
                  font-size: 1.5rem;

                  @media (max-width: 768px) {
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
        }

      }
  }
}

.advantage-box {
  justify-content: center;
  .advantage-box--content {

    &:nth-child(1) {
      .advantage-box__square {
        background: url('../img/atribute1.jpg') no-repeat 0px 0px;
        background-size: cover;
      }
    }
    &:nth-child(2) {
      .advantage-box__square {
        background: url('../img/atribute2.jpg') no-repeat 0px 0px;
        background-size: cover;
      }
    }
    

    .advantage-box__square {
      display: flex;
      align-items: flex-end;
      margin: 40px;
      color: #FFF;
      font-weight: 700;
      border-radius: 20px;
      height: 300px;
      overflow: hidden;

      &:hover {
        .gradient {
          background-color: #1d70b8;
          height: 100%;
          opacity: .8;
          display: flex;
          align-items: center;
          cursor: default;
        }
      }

      .gradient {
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.7rem;
        width: 100%;
        padding: 40px 25px 20px 25px;
        background: linear-gradient(to top,#1d70b8 0, #1d70b8 70%, rgba(29,112,184,0) 100%);
        height: 120px;
        transition: 0.5s ease-in-out;

        @media (max-width: 576px) {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }
  }
}

.advantage--button {
  margin-top: 50px;
  .btn {
    background-color: #1d70b8;
    border-radius: 20px;
    padding: 50px 80px;
    border: 1px #1d70b8 solid;
    transition: all 0.5s;
    // width: 380px;
    text-align: center;

    @media (max-width: 768px) {
      width: 300px;
    }

    &:hover {
      background-color: #FFF;
      span {
        padding-right: 25px;
        color: #1d70b8;

        &:after {
          opacity: 1;
          right: 0;
        }
      }
    }

    span {
      position: relative;
      font-size:  1.5rem;
      color: #FFF;
      text-transform: uppercase;
      transition: 0.5s;

      @media (max-width: 768px) {
        strong {display: block;}
      }

      &:after {
        content: '\00bb';
        font-size:  1.5rem;
        height: 25px;
        line-height: 25px;
        color: #1d70b8;
        position: absolute;
        opacity: 0;
        top: 0;
        right: -20px;
        transition: 0.5s;
      }
    }
  }
}

.footerpage {
  height: 200px;
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  background: url('../img/footer-bg-01.svg') no-repeat 0px 0px;
  background-size: cover;

  .logosvg {
    height: 100%;
  }

  svg {
    position: absolute;
    top: -100px; left: 0px;
    fill: #134064;
    width: 100%;
    z-index: 800;
  }

  .logo {
    fill: #FFF;
    margin-top: 100px;
    z-index: 900;
    width: 300px;
    margin: 0px auto;
  }
}


.navbar {
  z-index: 999;

  .navbar-toggler {
    border-width: 0px;
  }

  .animated-icon1 {
    width: 35px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  .animated-icon1 span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .animated-icon1 span {
    background: #000;
  }

  .animated-icon1 span:nth-child(1) {
    top: 0px;
  }

  .animated-icon1 span:nth-child(2) {
    top: 10px;
  }

  .animated-icon1 span:nth-child(3) {
    top: 20px;
  }

  .animated-icon1.open span:nth-child(1) {
    top: 11px;
    transform: rotate(135deg);
  }

  .animated-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .animated-icon1.open span:nth-child(3) {
    top: 11px;
    transform: rotate(-135deg);
  }

}

.wrap__main {
  position: relative;
  transition: 0.25s ease-in-out;
  left: 0px;

  .navbar-collapse {
    position: absolute;
    z-index: 999;
    background-color: #1d70b8;
    padding: 100px 50px;
    right: -300px;
    top: 0px;
    height: 100%;
    width: 300px;
    // display: block!important;

    @media (max-width: 576px) {
      width: 100%;
      right: -600px;
    }

    .navbar-nav {
      .nav-link {
        font-size: 1.7rem;
        font-weight: bold;
        text-align: center;
        color: #FFF;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #FFF;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out 0s;
        }

        &:hover:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }
}

.opened {
  overflow: hidden;

  .wrap__main {
    // cursor: url('../img/svg-close-icon-4.png'), auto;	
    left: -300px;

    @media (max-width: 576px) {
      left: -600px;
    }
    &::before {
      content: '';
      background-color: rgba(0,0,0,.5);
      position: absolute; 
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 999;
    }
  }

  .navbar-main {
    display: none;
  }

  .navbar-collapse {
    position: fixed;
    right: 0px;
    cursor: default;
    box-shadow: -10px 2px 5px 0 rgba(0,0,0,.16);

    .navbar {
      position: absolute;
      top: 20px;
      right: 20px;

      .navbar-toggler {
        // color: #FFF;

        span {
          background-color: #FFF;

          &:nth-child(1) {
            top: 11px;
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            top: 11px;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }
}

.modal-dialog {
  max-width: 60%;

  @media (max-width: 1200px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    max-width: 1000%;
  }
  // .modal-header, .modal-footer {
  //   display: none;
  // }
  .modal-body {
    padding: 0px;

    button.close {
      position: absolute;
      top: 10px;
      right: 25px;
      font-size: 4rem;
      color: #FFF;
      opacity: 1;
    }
  }
}

#cookies-info {
  width: 100%;
  padding: 10px 30px 10px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: fixed;
  bottom: 0px;
  z-index: 99999;
  p {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      color: #fff;
      margin: 0px;

      // @media (max-width: 768px) {
      //   padding: 0px 20px;
      //   height: 80px;
      // }
  }

  .cookies-ok {
      padding: 7px 15px 7px 15px;
      text-align: center;
      color: #fff;
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 14px;
      cursor: pointer;
      z-index: 999;
  }
}